import React, { useState } from "react";
import Layout from "./components/Layout";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CryptoJS from "crypto-js";
import generateSignature from "../api/apiAuth"; // Adjust the path accordingly
import serverApi from "../api/server";

const SenderNames = () => {
  const [senderList, setSenderList] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleInputChange = (event: any) => {
    setSenderList(event.target.value);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event: any) => {
    setSnackbarOpen(false);
  };

  const handleSubmit = () => {
    if (!senderList.trim()) {
      alert("Must submit list");
      return;
    }

    const lines = senderList.split("\n");
    const entries = lines.map((line) => {
      const [name, address] = line.split("\t");
      return { name, address };
    });

    setSenderList("");
    updateSenderNames(entries);
  };

  const updateSenderNames = async (entries: any) => {
    try {
      const timeStamp = new Date().toISOString();
      const path = '/api/updateSenders';
      const signature = generateSignature("POST", timeStamp, path, null, entries);

      const result = await serverApi.post('/updateSenders', entries, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': signature
          }
      });

      if (result.status === 200) {
          console.log('Senders updated successfully:', result.data);
          handleSnackbarOpen();
      } else {
          console.error('Failed to update senders:', result.data);
          alert('Failed to update names')
      }
      // console.log(JSON.stringify(entries));
    } catch (error) {
      console.error("Error sending data to backend:", error);
      alert("An error occurred while sending data");
      // Handle network or other unexpected errors here
    }
  };

  const renderTable = () => {
    const lines = senderList.split("\n");
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.map((line, index) => {
            const [name, address] = line.split("\t");
            return (
              <TableRow key={index}>
                <TableCell>{name}</TableCell>
                <TableCell>{address}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginTop: "2em" }}
        >
          Submit
        </Button>
      </Table>
    );
  };

  return (
    <Layout>
      <div>
        <h2>Add sender names to Axe</h2>
        <TextField
          label="Enter List"
          multiline
          value={senderList}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            List successfully submitted!
          </Alert>
        </Snackbar>
      </div>
      <div
        style={{ margin: "auto 3em", display: "flex", flexDirection: "column" }}
      >
        <h3 style={{ textAlign: "center" }}>Preview</h3>
        {renderTable()}
      </div>
    </Layout>
  );
};

export default SenderNames;
