import React, {useEffect, useState} from 'react';
import Layout from './components/Layout';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from './components/TabPanel';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { InputBase, InputAdornment, FormControl, Select, InputLabel, MenuItem, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
//import { B2C2Data, DVChainData } from '../mockData/tradesMock';
import { Typography } from 'antd';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import LinearProgress from '@mui/material/LinearProgress';
import TransactionInfoModal from './components/TransactionInfoModal';
import serverApi from '../api/server'
import generateSignature from '../api/apiAuth';
import { DVChainData } from '../mockData/tradesMock';
import { sign } from 'crypto';
const { Title } = Typography;

const isAxmed = process.env.REACT_APP_AXMED === 'true' ? true : false
function Trades() {
    const [B2C2, setB2C2] = useState<any[]>([]);
    const [DVChain, setDVChain] = useState<any[]>([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [curTxIndex, setCurTxIndex] = useState();
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [txData, setTxData] = useState();
    const [loading, setLoading] = useState(false);
    // filters for b2c2
    const [searchB2C2value, setSearchB2C2value] = useState('');
    const [b2c2SearchResult, setB2C2SearchResult] = useState<any[]>([]);
    const [openB2C2Filter, setOpenB2C2Filter] = useState(false);
    const [filterB2C2DateStart, setFilterB2C2DateStart] = useState(null);
    const [filterB2C2DateEnd, setFilterB2C2DateEnd] = useState(null);
    // filters for DVChain
    const [searchDVvalue, setSearchDVvalue] = useState('');
    const [dvSearchResult, setDVsearchResult] = useState<any[]>([]);
    const [openDVFilter, setOpenDVFilter] = useState(false);
    const [filterDVDateStart, setFilterDVDateStart] = useState(null);
    const [filterDVDateEnd, setFilterDVDateEnd] = useState(null);
    const handleTabChange = (event: any, newValue: any) => {
        setCurrentTab(newValue);
    };

    // data for B2C2 transaction table
    const getB2C2TransactionData = async() : Promise<any[]> => {
        try {
            // create sig

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = '/api/getB2C2Trades' 

            const signature = generateSignature("GET", timeStamp, path, null, null) 
            console.log("get b2c2 trades sig", signature)

            const result = await serverApi.get('/getB2C2Trades', {headers: {Authorization: signature}})
            console.log(result)
            let resultJSONData = result.data
            console.log(resultJSONData)
            return resultJSONData
        }
        catch (err) {
            alert(err)
            return []
        }
    }
    useEffect(() => {
        setLoading(true)
        getB2C2TransactionData().then(res => {
            setB2C2(res);
            setLoading(false)
        })
    }, [])
    // data for DVCHAIN transaction table
    const getDVChainTransactionData = async(): Promise<any[]> => {
        try {
            // get sig

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = '/api/getDVChainTrades' 

            const signature = generateSignature("GET", timeStamp, path, null, null) 

            const result = await serverApi.get('/getDVChainTrades', {headers: {Authorization: signature}})

            console.log(result)

            let finalData = result.data
            finalData = finalData.data
            // console.log(resultJSONData)
            console.log(finalData)
            if(finalData){
                return finalData.reverse()
            }
            else {
                return finalData
            }
        }
        catch (err) {
            console.log(err)
            return []
        }
    }
    useEffect(() => {
        setLoading(true)
        getDVChainTransactionData().then(res => {
            console.log(res)
            setDVChain(res)
            setLoading(false)
        })
    }, [])
    const handleOpenTxInfoDialog = (row: any, index: any) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    const handleCloseTxInfoDialog = () => {
        setOpenTxInfo(false)
        setCurTxIndex(undefined)
    }
    // search
    const handleB2C2searchValueChange = (e:any) => {
        let inputValue = (e.target.value).trim()
        setSearchB2C2value(inputValue)
        let result = B2C2.filter((i: any) => (i.instrument).toUpperCase().includes(inputValue.toUpperCase()) || (i.trade_id).toUpperCase().includes(inputValue.toUpperCase()))
        setB2C2SearchResult(result)
    }
    const handleClearB2C2SearchValue = () => {
        setSearchB2C2value('')
    }
    const handleSearchDVvalueChange = (e:any) => {
        let inputValue = (e.target.value).trim()
        setSearchDVvalue(inputValue)
        let result = DVChain.filter((i: any) => (i.asset).toUpperCase().includes(inputValue.toUpperCase()) || (i._id).toUpperCase().includes(inputValue.toUpperCase()))
        setDVsearchResult(result)
    }
    const handleClearDVSearchValue = () => {
        setSearchDVvalue('')
    }
    const clearB2C2Filters = () => {
        setFilterB2C2DateStart(null)
        setFilterB2C2DateEnd(null)
        // setTransactionData(transactions)
    }
    const closeB2C2FilterMenu = () => {
        setOpenB2C2Filter(false)
    }
    const openB2C2FilterMenu = () => {
        setOpenB2C2Filter(true)
    }
    const clearDVFilters = () => {
        setFilterDVDateStart(null)
        setFilterDVDateEnd(null)
        // setTransactionData(transactions)
    }
    const closeDVFilterMenu = () => {
        setOpenDVFilter(false)
    }
    const openDVFilterMenu = () => {
        setOpenDVFilter(true)
    }
    // console.log(searchDVvalue)
    console.log(DVChain)
    return (
        <Layout>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={handleTabChange}>
                        {isAxmed? null : <Tab label="B2C2 Transactions" />}
                        <Tab label="DVChain Transactions" />
                    </Tabs>
                    {isAxmed? null : <TabPanel value={currentTab} index={isAxmed? null : 0}>
                        <div className='clientsWalletContainer'>
                        <div className='fireblockTransactionContainer'>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 230, height: 36 ,marginBottom: 1, marginRight: 5 }}
                                >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        value={searchB2C2value}
                                        onChange={(e) => handleB2C2searchValueChange(e)}
                                        placeholder="Search Asset or Trade ID"
                                        inputProps={{ 'aria-label': 'search asset' }}
                                        // startAdornment={<SearchIcon /> }
                                        endAdornment={searchB2C2value? 
                                        <InputAdornment position="end">
                                        <IconButton
                                            size='small'
                                            onClick={handleClearB2C2SearchValue}
                                            style={{borderRadius: 0}}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        </InputAdornment> : <SearchIcon />}
                                    />
                                </Paper>
                                {openB2C2Filter? 
                                    <Paper elevation={2} sx={{display: 'inline-flex', flexDirection: 'row', marginBottom: 5, minHeight: 50, padding: 2, paddingX: 3, paddingY: 1.5}}>
                                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 8}}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="From"
                                                    value={filterB2C2DateStart}
                                                    onChange={(newValue: any) => {
                                                        setFilterB2C2DateStart(newValue);
                                                    }}
                                                    maxDate={filterB2C2DateEnd ? filterB2C2DateEnd : null}
                                                    renderInput={(params) => <TextField {...params} size="small"/>}
                                                />
                                            </LocalizationProvider>
                                            <span style={{margin: 5, textAlign: 'center'}}></span>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="To"
                                                    value={filterB2C2DateEnd}
                                                    onChange={(newValue: any) => {
                                                        setFilterB2C2DateEnd(newValue);
                                                    }}
                                                    minDate={filterB2C2DateStart ? filterB2C2DateStart : null}
                                                    renderInput={(params) => <TextField {...params} size="small"/>}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <Button sx={{marginLeft: 5, height: 30, alignSelf: 'center'}} variant="text" size="small" onClick={() => clearB2C2Filters()} disabled={!(filterB2C2DateStart || filterB2C2DateEnd)}>clear filter</Button>
                                        <IconButton
                                            aria-label="expand row"
                                            sx={{marginLeft: 5, height: 40, alignSelf: 'start'}}
                                            onClick={() => closeB2C2FilterMenu()}
                                        >
                                            <CloseIcon/>
                                        </IconButton>
                                    </Paper> : <Button variant="text" size="small" onClick={() => openB2C2FilterMenu()}>open filter</Button>}
                            </div>
                            <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                                {loading ? <LinearProgress color="success"/> : null}
                                <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Title level={3}>Date</Title></TableCell>
                                        {/* <TableCell align="left"><Title level={3}>User</Title></TableCell> */}
                                        <TableCell align="left"><Title level={3}>TX Type</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Asset</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Amount</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Price</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Trade ID</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Info</Title></TableCell>
                                        <TableCell align="left"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {searchB2C2value?
                                b2c2SearchResult
                                .filter(item => filterB2C2DateStart && filterB2C2DateEnd ? moment(item.created).isSameOrAfter(filterB2C2DateStart, 'day') && moment(item.created).isSameOrBefore(filterB2C2DateEnd): item)
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                                        <TableCell>{moment(row.created).format('L')}</TableCell>
                                        {/* <TableCell align='left'>{row.user}</TableCell> */}
                                        <TableCell align='left'>{row.side}</TableCell>
                                        <TableCell align='left'>{row.instrument}</TableCell>
                                        <TableCell align='left'>{Number(row.quantity).toFixed(4).replace(/(\.0+|0+)$/, '')}</TableCell>
                                        <TableCell align='left'>{Number(row.price).toFixed(4).replace(/(\.0+|0+)$/, '')}</TableCell>
                                        <TableCell align='left'>{row.trade_id}</TableCell>
                                        <TableCell align='left'>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => handleOpenTxInfoDialog(row, index)}
                                            >
                                                <InfoIcon color="success"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    )
                                )
                                :B2C2
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .filter(item => filterB2C2DateStart && filterB2C2DateEnd ? moment(item.created).isSameOrAfter(filterB2C2DateStart, 'day') && moment(item.created).isSameOrBefore(filterB2C2DateEnd): item)
                                .map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                                        <TableCell>{moment(row.created).format('L')}</TableCell>
                                        {/* <TableCell align='left'>{row.user}</TableCell> */}
                                        <TableCell align='left'>{row.side}</TableCell>
                                        <TableCell align='left'>{row.instrument}</TableCell>
                                        <TableCell align='left'>{Number(row.quantity).toFixed(4).replace(/(\.0+|0+)$/, '')}</TableCell>
                                        <TableCell align='left'>{Number(row.price).toFixed(4).replace(/(\.0+|0+)$/, '')}</TableCell>
                                        <TableCell align='left'>{row.trade_id}</TableCell>
                                        <TableCell align='left'>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => handleOpenTxInfoDialog(row, index)}
                                            >
                                                <InfoIcon color="success"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    )
                                )}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        </div>
                    </TabPanel>}
                    <TabPanel value={currentTab} index={isAxmed? 0 : 1}>
                        <div className='clientsWalletContainer'>
                            <div className='fireblockTransactionContainer'>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 230, height: 36 ,marginBottom: 1, marginRight: 5 }}
                                >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        value={searchDVvalue}
                                        onChange={(e) => handleSearchDVvalueChange(e)}
                                        placeholder="Search Asset or Trade ID"
                                        inputProps={{ 'aria-label': 'search asset or trade ID' }}
                                        // startAdornment={<SearchIcon /> }
                                        endAdornment={searchDVvalue? 
                                        <InputAdornment position="end">
                                        <IconButton
                                            size='small'
                                            onClick={handleClearDVSearchValue}
                                            style={{borderRadius: 0}}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        </InputAdornment> : <SearchIcon />}
                                    />
                                </Paper>
                                {openDVFilter? 
                                    <Paper elevation={2} sx={{display: 'inline-flex', flexDirection: 'row', marginBottom: 5, minHeight: 50, padding: 2, paddingX: 3, paddingY: 1.5}}>
                                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 8}}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="From"
                                                    value={filterDVDateStart}
                                                    onChange={(newValue: any) => {
                                                        setFilterDVDateStart(newValue);
                                                    }}
                                                    maxDate={filterDVDateEnd ? filterDVDateEnd : null}
                                                    renderInput={(params) => <TextField {...params} size="small"/>}
                                                />
                                            </LocalizationProvider>
                                            <span style={{margin: 5, textAlign: 'center'}}></span>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="To"
                                                    value={filterDVDateEnd}
                                                    onChange={(newValue: any) => {
                                                        setFilterDVDateEnd(newValue);
                                                    }}
                                                    minDate={filterDVDateStart ? filterDVDateStart : null}
                                                    renderInput={(params) => <TextField {...params} size="small"/>}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <Button sx={{marginLeft: 5, height: 30, alignSelf: 'center'}} variant="text" size="small" onClick={() => clearDVFilters()} disabled={!(filterDVDateStart || filterDVDateEnd)}>clear filter</Button>
                                        <IconButton
                                            aria-label="expand row"
                                            sx={{marginLeft: 5, height: 40, alignSelf: 'start'}}
                                            onClick={() => closeDVFilterMenu()}
                                        >
                                            <CloseIcon/>
                                        </IconButton>
                                    </Paper> : <Button variant="text" size="small" onClick={() => openDVFilterMenu()}>open filter</Button>}
                                </div>
                                <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                                    {loading ? <LinearProgress color="success"/> : null}
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Title level={3}>Date</Title></TableCell>
                                            {/* <TableCell align="left"><Title level={3}>User</Title></TableCell> */}
                                            <TableCell align="left"><Title level={3}>TX Type</Title></TableCell>
                                            <TableCell align="left"><Title level={3}>Asset</Title></TableCell>
                                            <TableCell align="left"><Title level={3}>Counter Asset</Title></TableCell>
                                            <TableCell align="left"><Title level={3}>Amount</Title></TableCell>
                                            <TableCell align="left"><Title level={3}>Price</Title></TableCell>
                                            <TableCell align="left"><Title level={3}>Trade ID</Title></TableCell>
                                            <TableCell align="left"><Title level={3}>Info</Title></TableCell>
                                            <TableCell align="left"/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {searchDVvalue?
                                    dvSearchResult&& dvSearchResult
                                    .filter(item => filterDVDateStart && filterDVDateEnd ? moment(item.updatedAt).isSameOrAfter(filterDVDateStart, 'day') && moment(item.updatedAt).isSameOrBefore(filterDVDateEnd): item)
                                    .map((row, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                                            <TableCell>{moment(row.createdAt).format('L')}</TableCell>
                                            {/* <TableCell align='left'>{row.user.firstName} {row.user.lastName}</TableCell> */}
                                            <TableCell align='left'>{row.side}</TableCell>
                                            <TableCell align='left'>{row.asset}</TableCell>
                                            <TableCell align='left'>{row.counterAsset}</TableCell>
                                            <TableCell align='left'>{Number(row.quantity)}</TableCell>
                                            <TableCell align='left'>{Number(row.price)}</TableCell>
                                            <TableCell align='left'>{row._id}</TableCell>
                                            <TableCell align='left'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleOpenTxInfoDialog(row, index)}
                                                >
                                                    <InfoIcon color="success"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :DVChain &&DVChain
                                    .filter(item => filterDVDateStart && filterDVDateEnd ? moment(item.updatedAt).isSameOrAfter(filterDVDateStart, 'day') && moment(item.updatedAt).isSameOrBefore(filterDVDateEnd): item)
                                    .map((row, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                                            <TableCell>{moment(row.createdAt).format('L')}</TableCell>
                                            {/* <TableCell align='left'>{row.user.firstName} {row.user.lastName}</TableCell> */}
                                            <TableCell align='left'>{row.side}</TableCell>
                                            <TableCell align='left'>{row.asset}</TableCell>
                                            <TableCell align='left'>{row.counterAsset}</TableCell>
                                            <TableCell align='left'>{Number(row.quantity).toFixed(4).replace(/(\.0+|0+)$/, '')}</TableCell>
                                            <TableCell align='left'>{Number(row.price).toFixed(4).replace(/(\.0+|0+)$/, '')}</TableCell>
                                            <TableCell align='left'>{row._id}</TableCell>
                                            <TableCell align='left'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleOpenTxInfoDialog(row, index)}
                                                >
                                                    <InfoIcon color="success"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        )
                                    )}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        </div>
                    </TabPanel>
                </Box>
            </Box>
            <TransactionInfoModal 
                openDialog={openTxInfo} 
                closeDialog={handleCloseTxInfoDialog}
                data={txData}
            />
        </Layout>
    );
}

export default Trades;
